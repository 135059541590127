import React from 'react';

import {Container} from '../components/commons';
import SEO from '../components/seo'
import Entries from '../data/timeline.json'
import { StoryImage } from '../components/image'

const TimelineSection = () => {
  const sort = (a, b) => (a.year - b.year)
  const entries = Entries.sort(sort).map((entry) => {
    return (
      <div key={entry.year} className="entry mb2">
        <h2 className="year">{entry.year}</h2>
        <hr />
        <p className="content" dangerouslySetInnerHTML={{ __html: entry.content }} />
      </div>
    );
  });

  return (
    <>
      <SEO
        title="Timeline"
        keywords={['gatsby', 'portfolio', 'naveen kumar sangi', 'naveenkumarsangi']} />
      <Container className="timeline">
        <StoryImage />
        <div className="mt4">
          {entries}
        </div>
      </Container>
    </>
  )
};

export default TimelineSection;
