import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const query = graphql`
  query {
    storyLeader: file(relativePath: { eq: "story.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const StoryImage = () => (
  <StaticQuery
    query={query}
    render={data => <Img fluid={data.storyLeader.childImageSharp.fluid} />}
  />
)
